
export default {
	data() {
		return {
			currentlyYear: null,
			lastYear: null,

			featured__list: [
				{
					title: 'Внедорожники',
					link: '/cars/allroad',
					img: {
						x1: require('~/assets/img/featured/selections/selections-6@x4.webp'),
						x2: require('~/assets/img/featured/selections/selections-6@x4.webp')
					}
				},
				{
					title: 'Седаны',
					link: '/cars/sedan',
					img: {
						x1: require('~/assets/img/featured/selections/selections-2@x4.webp'),
						x2: require('~/assets/img/featured/selections/selections-2@x4.webp')
					}
				},
				{
					title: 'Для такси',
					link: '/cars/for-taxi',
					img: {
						x1: require('~/assets/img/featured/selections/selections-1@x4.webp'),
						x2: require('~/assets/img/featured/selections/selections-1@x4.webp')
					}
				},
				{
					title: 'Бизнес-класс',
					link: '/cars/business-class',
					img: {
						x1: require('~/assets/img/featured/selections/selections-3@x2.webp'),
						x2: require('~/assets/img/featured/selections/selections-3@x3.webp')
					}
				},
				{
					title: 'Для нее',
					link: '/cars/for-woman',
					img: {
						x1: require('~/assets/img/featured/selections/selections-5@x2.webp'),
						x2: require('~/assets/img/featured/selections/selections-5@x3.webp')
					}
				},
				{
					title: 'Семейные',
					link: '/cars/for-family',
					img: {
						x1: require('~/assets/img/featured/selections/selections-4@x4.webp'),
						x2: require('~/assets/img/featured/selections/selections-4@x4.webp')
					}
				},

			]
		}
	},
	mounted() {
		const sw = new swiper.default('.swiper.swiper--selections', {
			modules: [swiper.Navigation, swiper.Autoplay, swiper.Pagination],
			loop: true,
			// autoplayDisableOnInteraction: true,
			autoplay: false,
			// loop:true,
			initialSlide: 0,
			// centeredSlides: true,
			watchSlidesProgress: true,
			// centeredSlidesBounds:true,
			// roundLengths: true, 
			pagination: {
				clickable: true,
				el: ".swiper-pagination.swiper-pagination--featured__list",
				type: "bullets",
			},
			// on:{
			// 	init:()=>alert('smkdnfsbasddkhfbahbs')
			// },
			// centerInsufficientSlides:true,
			// slidesOffsetAfter: this.$refs.asdd.getBoundingClientRect().left,
			// cssMode:true,
			// slidesOffsetBefore: leftPadding,
			// slidesOffsetAfter: leftPadding,
			slidesPerView: 2,
			spaceBetween: 10,
			// grid: {
			// 	fill:'rows',
			// 	rows: 2,

			// },
			breakpoints: {
				// when window width is >= 320px
				// 220: {
				// 	slidesPerView: 1.25,
				// 	spaceBetween: 20
				// },
				600: {
					slidesPerView: 4,
					spaceBetween: 30,

				},
				1300: {
					slidesPerView: 6,
					spaceBetween: 30,

				}

			},

			navigation: {
				nextEl: '.swiper-button.swiper-button-next--featured__list',
				prevEl: '.swiper-button.swiper-button-prev--featured__list',
			},
		})
	}
}
